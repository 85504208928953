<template>
    <div>
      <div id="footer" class="container-fluid">
        <div class="row">
          <div class="container">
            <div class="col-md-5 QRcode">
              <el-avatar shape="square" :size="150" fit="fit" :src="codeurl"></el-avatar>
              <div><p>微信公众号</p></div>
          </div>
          <div class="col-md-3 link">
            <p>友情链接</p>
            <p><a :href="link" target="_blank">南宁觅明科技有限公司</a></p>
            <p><a href="https://blog.csdn.net/hao1__" target="_blank">LCIOT博客</a></p>
          </div>
          <div class="col-md-4 text">
            <p>联系我们</p>
            <p><i class="el-icon-phone"></i>  姓名—电话：{{ nameandphone }}</p>
            <p><i class="el-icon-location"></i>  地址：{{ address }}</p>
            <p><i class="el-icon-message"></i>  E-mall：{{ email }}</p>
            <p><i><a :href="qq">QQ：2824881988</a></i></p>
            <p><i class="el-icon-chat-dot-square"></i><a :href="qun">技术交流群</a></p>
            <p><i class="el-icon-shopping-cart-1"></i><a :href="taobaoshop">淘宝店铺</a></p>
          </div>
          </div>
        </div>
      </div>
        <div style="text-align:center;background:#444;color: #b3b3b3;font-size: 14px;"><a href="http://www.beian.gov.cn/" style="color:#fff;">{{beian}}</a></div>
    </div>
</template>

<script>
import {post,get} from "../network/request";
export default {
    data() {
        return {
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          nameandphone:'',
          address:'',
          email:'',
          qun:'',
          qq:'',
          taobaoshop:'',
          link:'',
          codeurl:'',
          beian:''
        }
    },
    created(){
      this.getfooter_data()
    },
    methods: {
      getfooter_data(){
        get('/ess/ess-footer/getLatest',{

        }).then(res=>{
          console.log(res);
          if(res.data.code ==200)
          {
            this.nameandphone = res.data.data.conractPerson + res.data.data.phone;
            this.address = res.data.data.address;
            this.email = res.data.data.email;
            this.qq = res.data.data.qq
            this.qun = res.data.data.qqGroup;
            this.link = res.data.data.link;
            this.taobaoshop = res.data.data.taobaoShop
            this.codeurl = res.data.data.qrCodeUrl
            this.beian = res.data.data.recordInformation
          }
        }).catch(err=>{
          console.log(err);
        })
      }
    }
}
</script>

<style lang="less" scoped>
    #footer{
      width: 100%;
      // height: 400px;
      overflow: hidden;
      background-color:#444;
      color: #fff;
      a{
        color: #fff;
      }
      .QRcode{
        // padding:30px 20% 0;
        margin-top: 30px;
        >p{
          font-size:20px;
        }
      }
      .link{
        
        // padding:30px 20% 0;
        margin-top: 30px;
        >p{
          font-size: 18px;
        }
      }
      .text{
        // float: right;
        // margin-left: 100px;
        margin-top: 30px;
        margin-bottom: 20px;
        >p{
          font-size:18px;
          line-height: 30px;
          // text-align: left;
        }
      }
    }
</style>