<template>
    <div>
      <div class="cbbfixed" style="bottom: 6rem;">
      <a class="cbbtn" @click="goTop">
        <span class="up-icon"></span>
        <span>回到顶部</span>
      </a>
      <div v-for="item in data" :key="item.id"  class="cbbtn douyin">
        <span class="dy-icon">
          <img  :src="url + item.iconUrl" alt="">
        </span>
        <span>{{item.qrCodeName}}</span>
        <div class="qrcode-box">
          <img :src="url + item.qrCodeUrl"/>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import {get} from '../network/request'
import {BASE_URL} from '../network/config/index'
export default {
    data() {
        return {
          data:[],
          url:BASE_URL + '/'
        };
    },
    created() {

    },
    mounted() {
      this.getcode()
    },
    watch: {

    },
    methods: {
      // 回到顶部
      goTop () {
        cancelAnimationFrame(this.timer)
        const self = this
        self.timer = requestAnimationFrame(function fn () {
          const oTop = document.body.scrollTop || document.documentElement.scrollTop
          if (oTop > 0) {
            document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
            self.timer = requestAnimationFrame(fn)
          } else {
            cancelAnimationFrame(self.timer)
          }
        })
    },
      getcode(){
        get('/ess-publicity-qrcode/listUser',{

        }).then(res=>{
          console.log(res);
          this.data = res.data.data
        })
      },
    },
};
</script>

<style lang="less" scoped>
.up-icon{
    margin: 5px auto;
    width: 32px;
    height: 35px;
    background: url('../assets/img/top.png') no-repeat;
}
// 快手icon
.ks-icon{
  margin: 5px auto;
  width: 32px;
  height: 32px;
  background: url('../assets/img/ks-icon.png') no-repeat;
}
// b站icon
.bz-icon{
  margin: 5px auto;
  width: 32px;
  height: 32px;
  background: url('../assets/img/bz-icon.png') no-repeat;
}
// 公众号icon
.gzh-icon{
  margin: 5px auto;
  width: 32px;
  height: 35px;
  background: url('../assets/img/gzh-icon.png') no-repeat;
}

// 二维码
.qrcode-box {
    width: 198px;
    position:relative;
    right:21rem;
    bottom:19rem;
    height: 200px;
    display: none;
}
.douyin{
  &:hover .qrcode-box{
    display: block;
    animation: fade-in 0.5s linear forwards;
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.dy-icon{
  margin: 5px auto;
  width: 32px;
  height: 35px;
  // background: url('../assets/img/douyin.png') no-repeat;

}
.cbbfixed .cbbtn span {
    display: block;
    font-size: 12px;
    text-align: center;
}
.cbbfixed .cbbtn{
    width: 105px;
    height: 85px;
    padding: 10px;
    display: block;
    // overflow: hidden;
    border-bottom: 1px solid #ddd;
    
}
.cbbfixed{
  position: fixed;
  right: 10px;
  transition: bottom ease .3s;
  // bottom: -85px;
  z-index: 3;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
}
</style>
